import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BaseService } from '../base.service';
import { environment as config } from '../../../../environments/environment'
import { Observable } from 'rxjs/internal/Observable';

@Injectable({
  providedIn: 'root'
})
export class ContactsService extends BaseService {
  prefixURL = 'contacts';
  constructor(public override http: HttpClient) {
    super(http);
    this.apiURL = config.ygt_subs_api;
  }

  create(body: any, reCaptchaToken: string): Observable<any> {
    return this.post(`${this.prefixURL}`, { ...body, reCaptchaToken });
  }
}
