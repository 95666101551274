<app-header></app-header>
<section class="p60-top-bottom">
    <div class="container our-service">
        <div class="row mt-4 mt-md-0">
            <div class="col-12">
                <h1 class="main-title">Featured Services</h1>
            </div>
        </div>
        <div class="row">
            <div class="col-lg-4 col-md-12 p40-bottom">
                <img class="p40-bottom" src="../../../assets/images/img-our-service1.jpg" alt="" />
                <h2><strong>Comprehensive Maintenance Programme</strong></h2>
                <p>Includes regular servicing, wear and tear parts replacement, oil and coolant charging, tire rotation
                    and alignment.</p>
            </div>
            <div class="col-lg-4 col-md-12 p40-bottom">
                <img class="p40-bottom" src="../../../assets/images/img-our-service2.jpg" alt="" />
                <h2><strong>Replacement Vehicle</strong></h2>
                <p>Replacement vehicle will be provided when yours is servicing.</p>
            </div>
            <div class="col-lg-4 col-md-12 p40-bottom">
                <img class="p40-bottom" src="../../../assets/images/img-our-service3.jpg" alt="" />
                <h2><strong>Fuss-free Aftersales Support</strong></h2>
                <p>All administrative matters will be taken care of in our leasing programme – from liaising
                    withinsurers, renewing road tax and tedious paperwork.</p>
            </div>
        </div>


        <div class="row">
            <div class="col-lg-8 p40-bottom"><img src="../../../assets/images/img-our-service4.jpg" alt="" /></div>
            <div class="col-lg-4 p40-bottom">
                <h2><strong>Digital Solutions</strong></h2>
                <p>Complemented by our digital solutions, our EVs are enhanced with smart technology features to
                    complete your EV experience.</p>
                <ul>
                    <li>Advanced Driver Assistance Systems</li>
                    <li>Driver Behaviour Monitoring System</li>
                    <li>Driver Safety Scorecard</li>
                    <li>Battery Status Monitoring</li>
                    <li>Navigation to Charging Stations</li>
                    <li>Virtual Keys</li>
                </ul>
                <div class="btn btn-gradient"><a routerLink="/contact-us">Enquire Now <i
                            class="fa-solid fa fa-arrow-right"></i></a></div>
            </div>
        </div>
    </div>
    <div class="bg-blue">
        <div class="container">
            <div class="row ">
                <div class="col-12">
                    <h1 class="title">FLEET MONITORING DASHBOARD</h1>
                </div>
            </div>
            <div class="row fleet-monitor">
                <div class="col-lg-4">
                    <img src="../../../assets/images/driving-behaviour-dashboard.jpg">
                    <h2><strong>Driver Behaviour</strong></h2>
                    <p>Analyze driving behaviour by collecting data on acceleration, braking, speeding, harsh cornering
                        and excessive idling</p>
                </div>
                <div class="col-lg-4">
                    <img src="../../../assets/images/charging-efficiency.jpg">
                    <h2><strong>Charging Efficiency</strong></h2>
                    <p>Monitor real-time charging status such as total energy charged, charging time per day, no. of
                        charging per day</p>
                </div>
                <div class="col-lg-4">
                    <img src="../../../assets/images/carbon-footprint.jpg">
                    <h2><strong>Carbon Footprint Tracking</strong></h2>
                    <p>Track your carbon footprint closely with parameters such as energy consumption per day, energy
                        efficiency per day, carbon footprint per day</p>
                </div>
            </div>
        </div>
    </div>
</section>
<section>
    <div class="container">
        <div class="row">
            <div class="col-lg-3 col-md-12 p40-bottom">
                <h1 class="title">FLEXIBLE UPGRADES</h1>
                <p>Prefer variation instead of something fixed? During your lease period with us, you can enjoy the
                    privilege of upgrading your EV to something new in our catalogue in the future.</p>
                <div class="btn btn-gradient"><a routerLink="/contact-us">Enquire Now <i
                            class="fa-solid fa fa-arrow-right"></i></a></div>
            </div>
            <div class="col-lg-9 col-md-12 p40-bottom"><img src="../../../assets/images/img-drive-new.jpg"></div>
        </div>
    </div>

</section>