import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { FooterComponent } from './templates/footer/footer.component';
import { HomeComponent } from './pages/home/home.component';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { ErrorHandlingInterceptor } from './shared/interceptors/error-handling.interceptor';
import { SharedModule } from './shared/shared.module';
import { HomeHeaderComponent } from './templates/home-header/home-header.component';
import { NotifierModule, NotifierOptions } from 'angular-notifier';
import { CONSTANTS } from './shared/common/constants';
import { ContactUsComponent } from './pages/contact-us/contact-us.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ChargingSolutionsComponent } from './pages/charging-solutions/charging-solutions.component';
import { OurServicesComponent } from './pages/our-services/our-services.component';
import { AboutUsComponent } from './pages/about-us/about-us.component';
import { FeaturedEvComponent } from './pages/featured-ev/featured-ev.component';
import { NgxLoadingModule } from 'ngx-loading';
import { SeoService } from './shared/services/seo.service';

@NgModule({
  declarations: [
    AppComponent,
    FooterComponent,
    HomeComponent,
    HomeHeaderComponent,
    ContactUsComponent,
    ChargingSolutionsComponent,
    FeaturedEvComponent,
    OurServicesComponent,
    AboutUsComponent
  ],
  imports: [
    BrowserModule.withServerTransition({ appId: 'serverApp' }),
    AppRoutingModule,
    SharedModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    NotifierModule.withConfig(CONSTANTS.NOTIFIER_OPTIONS as NotifierOptions),
    NgxLoadingModule.forRoot({})
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ErrorHandlingInterceptor,
      multi: true,
    },
    SeoService
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }


