import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ModelsService } from 'src/app/shared/services/models/models.service';
import { UtilsService } from 'src/app/shared/services/utils/utils.service';
import { APP_ROUTES } from '../../shared/common/app-routes';

@Component({
  selector: 'app-featured-ev',
  templateUrl: './featured-ev.component.html',
  styleUrls: ['./featured-ev.component.scss']
})
export class FeaturedEvComponent implements OnInit {
  cars = [] as any;
  carsToShow = [] as any;
  makeId: any;
  loading = false;

  EV_TYPE = {
    ALL: 'all',
    EV_CARS: 'ev_car',
    EV_VANS: 'ev_van',
  }
  EVTypeSelected = this.EV_TYPE.ALL;
  title = 'EVs';
  APP_ROUTES = APP_ROUTES;
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private utilsService: UtilsService,
    private modelsService: ModelsService,
  ) {
    this.route.queryParams.subscribe((params: any) => {
      this.makeId = params && params.makeId;
      let condition = {} as any;
      if (this.makeId) {
        condition.makeId = this.makeId
      }
      this.getDataFeaturedEV(condition)
    });
  }

  ngOnInit(): void {
  }

  getDataFeaturedEV(params: any) {
    let self = this;
    self.loading = true;
    this.modelsService.getDataFeaturedEV(params).subscribe({
      next(result) { handleSuccess(result); },
      error(error) { handleError(error) },
    });

    function handleSuccess(result: any) {
      self.cars = result && result.data || [];
      self.carsToShow = JSON.parse(JSON.stringify(self.cars));
      self.loading = false;
    }
    function handleError(error: any) {
      self.utilsService.notifyError(error.message);
      self.loading = false;
    }
  }

  filterByEVType(type: string) {
    if (!type) {
      return;
    }

    this.EVTypeSelected = type;
    this.carsToShow = JSON.parse(JSON.stringify(this.cars));
    this.carsToShow = this.carsToShow.filter((car: { kind: string; }) => {
      return this.EVTypeSelected === this.EV_TYPE.ALL || car.kind === this.EVTypeSelected;
    });

    switch (this.EVTypeSelected) {
      case this.EV_TYPE.ALL:
        this.title = 'EVs';
        break;
      case this.EV_TYPE.EV_CARS:
        this.title = 'EV Cars';
        break;
      case this.EV_TYPE.EV_VANS:
        this.title = 'EV Vans';
        break;
      default:
        this.title = 'EVs';
        break;
    }
  }

  viewDetails(key: string) {
    if (!key) {
      return;
    }
    this.router.navigate([`${APP_ROUTES.CAR}/${key}`]);
  }

  convertFrequency(frequency: string) {
    switch (frequency) {
      case 'W':
        return " / Week"
      case 'M':
        return '/ Month'
      case 'D':
        return '/ Day'
      default:
        return ''
    }
  }
}
