<app-header></app-header>
<section id="subs-form" class="p60-top-bottom">
    <div class="container">
        <div class="row align-self-stretch eq-container mt-4 mt-md-0">
            <div class="col-md-12 eq-item">
                <div class="bg-white">
                    <div class="form-title">Let us know how we can help</div>
                    <form [formGroup]="contactForm">
                        <div class="row">
                            <div class="col-xs-12 col-md-6">
                                <label class="required-field">First Name</label>
                                <input type="text" placeholder="Enter First Name" formControlName="firstName"
                                    [ngClass]="{ 'is-invalid': submitted && f['firstName'].errors}">
                                <div *ngIf="submitted && f['firstName'].errors" class="invalid-feedback">
                                    <div *ngIf="f['firstName'].errors['required']">First Name is required</div>
                                </div>
                            </div>
                            <div class="col-xs-12 col-md-6">
                                <label class="required-field">Last Name</label>
                                <input type="text" placeholder="Enter Last Name" formControlName="lastName"
                                    [ngClass]="{ 'is-invalid': submitted && f['lastName'].errors}">
                                <div *ngIf="submitted && f['lastName'].errors" class="invalid-feedback">
                                    <div *ngIf="f['lastName'].errors['required']">Last Name is required</div>
                                </div>
                            </div>
                            <div class="col-xs-12 col-md-6">
                                <label class="required-field">Contact Number</label>
                                <input type="text" placeholder="Enter Contact Number" formControlName="contactNumber"
                                    [ngClass]="{ 'is-invalid': submitted && f['contactNumber'].errors}">
                                <div *ngIf="submitted && f['contactNumber'].errors" class="invalid-feedback">
                                    <div *ngIf="f['contactNumber'].errors['required']">Contact Number is required</div>
                                </div>
                            </div>
                            <div class="col-xs-12 col-md-6">
                                <label class="required-field">Email Address</label>
                                <input type="text" placeholder="Enter Email Address" formControlName="email"
                                    [ngClass]="{ 'is-invalid': submitted && f['email'].errors}">
                                <div *ngIf="submitted && f['email'].errors" class="invalid-feedback">
                                    <div *ngIf="f['email'].errors['required']">Email Address is required</div>
                                </div>
                            </div>
                            <div class="col-xs-12 col-md-6">
                                <label class="required-field">Company Name</label>
                                <input type="text" placeholder="Enter Company Name" formControlName="companyName"
                                    [ngClass]="{ 'is-invalid': submitted && f['companyName'].errors}">
                                <div *ngIf="submitted && f['companyName'].errors" class="invalid-feedback">
                                    <div *ngIf="f['companyName'].errors['required']">Company Name is required</div>
                                </div>
                            </div>
                            <div class="col-xs-12 col-md-6">
                                <label class="required-field">Vehicle Category</label>
                                <select name="vehicleCategory" formControlName="vehicleCategory"
                                    [ngClass]="{ 'is-invalid': submitted && f['vehicleCategory'].errors}"
                                    (change)="onChangeVehicleCategory()">
                                    <option value="">Select Vehicle Category</option>
                                    <option value="{{item}}"
                                        *ngFor="let item of CONSTANTS.CONTACT_US_VEHICLE_CATEGORIES">
                                        {{item}}
                                    </option>
                                </select>
                                <div *ngIf="submitted && f['vehicleCategory'].errors" class="invalid-feedback">
                                    <div *ngIf="f['vehicleCategory'].errors['required']">Vehicle Category is required
                                    </div>
                                </div>
                            </div>
                            <div class="col-xs-12 col-md-6" *ngIf="f['vehicleCategory'].value">
                                <label>Make & Model</label>
                                <select name="makeModel" formControlName="makeModel"
                                    [ngClass]="{ 'is-invalid': submitted && f['makeModel'].errors}">
                                    <option value="">Select Make & Model</option>
                                    <option value="{{item.name}}" *ngFor="let item of makeModelToShow">
                                        {{item.name}}
                                    </option>
                                </select>
                                <div *ngIf="submitted && f['makeModel'].errors" class="invalid-feedback">
                                    <div *ngIf="f['makeModel'].errors['required']">Make & Model is required
                                    </div>
                                </div>
                            </div>
                            <div class="col-xs-12 col-md-6">
                                <label>Tenure</label>
                                <select name="budget" formControlName="tenure"
                                    [ngClass]="{ 'is-invalid': submitted && f['tenure'].errors}">
                                    <option value="">Select Tenure</option>
                                    <option value="{{item}}" *ngFor="let item of CONSTANTS.CONTACT_US_TENURES">
                                        {{item}}
                                    </option>
                                </select>
                                <div *ngIf="submitted && f['tenure'].errors" class="invalid-feedback">
                                    <div *ngIf="f['tenure'].errors['required']">Tenure is required
                                    </div>
                                </div>
                            </div>
                            <div class="col-xs-12 col-md-6">
                                <label>Purpose</label>
                                <select name="purpose" formControlName="purpose"
                                    [ngClass]="{ 'is-invalid': submitted && f['purpose'].errors}">
                                    <option value="">Select Purpose</option>
                                    <option value="{{item}}" *ngFor="let item of CONSTANTS.CONTACT_US_PURPOSES">
                                        {{item}}
                                    </option>
                                </select>
                                <div *ngIf="submitted && f['purpose'].errors" class="invalid-feedback">
                                    <div *ngIf="f['purpose'].errors['required']">Purpose is required
                                    </div>
                                </div>
                            </div>
                            <div class="col-xs-12 col-md-6">
                                <label>Industry Type</label>
                                <select name="industryType" formControlName="industryType"
                                    [ngClass]="{ 'is-invalid': submitted && f['industryType'].errors}">
                                    <option value="">Select Industry Type</option>
                                    <option value="{{item}}" *ngFor="let item of CONSTANTS.CONTACT_US_INDUSTRIES">
                                        {{item}}
                                    </option>
                                </select>
                                <div *ngIf="submitted && f['industryType'].errors" class="invalid-feedback">
                                    <div *ngIf="f['industryType'].errors['required']">Industry Type is required</div>
                                </div>
                            </div>

                            <div class="col-xs-12 col-md-12">
                                <label></label>
                                <a class="btn btn-gradient submit-btn" (click)="submit()">Enquire Now
                                    <span class="glyphicon glyphicon-arrow-right" aria-hidden="true">
                                    </span>
                                </a>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
    <div class="form-group">
        <div id="captcha_element_enquiry_form" class="g-recaptcha" [attr.data-sitekey]="siteKey"></div>
    </div>
</section>
<ngx-loading [show]="loading" [config]="{ backdropBorderRadius : '3px', fullScreenBackdrop: true }"></ngx-loading>