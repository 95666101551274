import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { NotifierService } from 'angular-notifier';
import { APP_ROUTES } from '../../common/app-routes';

@Injectable({
  providedIn: 'root'
})
export class UtilsService {
  constructor(
    private router: Router,
    private readonly notifier: NotifierService
  ) { }

  redirectToNotFoundPage() {
    this.router.navigate([APP_ROUTES.NOT_FOUND])
  }

  redirectToHome() {
    this.router.navigate([APP_ROUTES.HOME])
  }

  notifyError(message: string = 'Error!') {
    this.notifier.notify('error', message);
  }

  notifySuccess(message: string = 'Success!') {
    this.notifier.notify('success', message);
  }

  notifyWarning(message: string = 'Warning!') {
    this.notifier.notify('warning', message);
  }

  encodeString(string: string) {
    return btoa(string);
  }

  decodeString(string: string) {
    return atob(string);
  }
}
