// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  authorization_key: 'ygt_subs_website',
  authorization_value: '8HFsEl6DuEjFbdegye76ededS7DEgyG6ws',
  ygt_subs_api: "https://hyprdrive-api.gocardev.com/ygt-subs-api",
  // ygt_subs_api: "http://localhost:8039/ygt-subs-api",
  siteKeyCaptcha: '6LfIwawUAAAAAFKkkeXdwYjRAOZFimjnoaFzHA9a',
  sg_website_url: 'https://latest---hypr-drive-ao3o4udqhq-as.a.run.app'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
