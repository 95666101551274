import { Component } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { APP_ROUTES } from './shared/common/app-routes';
import { SeoService } from './shared/services/seo.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'ygt-subs-website';

  constructor(private seoService: SeoService, private router: Router) {
    // listen to events from Router
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        // event is an instance of NavigationEnd, get url!  
        const url = event.urlAfterRedirects;
        console.log('url is ==>', url);
        if (url) {
          this.seoService.updateMetaByRouter(url.slice(1))
        }
      }
    })
  }
}
