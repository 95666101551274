import { Injectable } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { APP_ROUTES } from '../common/app-routes';

@Injectable()
export class SeoService {

    constructor(private meta: Meta, private titleService: Title) { }

    updateMetaByRouter(router: string) {
        let data: any = {}
        switch (router) {
            case APP_ROUTES.HOME:
                data.title = "Hyprdrive – Simply Electric, Easy Leasing"
                data.description = "Hyprdrive – Simply Electric, Easy Leasing"
                data.url = "https://hyprdrive.asia/"
                data.keywork = ""
                data.imageUrl = `${location.origin}/assets/images/bg-hero-image.jpg`
                break;
            case APP_ROUTES.CONTACT_US:
                data.title = "Hyprdrive – contact us"
                data.description = "Hyprdrive – contact us"
                data.url = "https://hyprdrive.asia/"
                data.keywork = ""
                data.imageUrl = `${location.origin}/assets/images/bg-hero-image.jpg`
                break;
            case APP_ROUTES.CHARGING_SOLUTIONS:
                data.title = "Hyprdrive – charging solutions"
                data.description = "Hyprdrive – charging solutions"
                data.url = "https://hyprdrive.asia/"
                data.keywork = ""
                data.imageUrl = `${location.origin}/assets/images/bg-hero-image.jpg`
                break;
            case APP_ROUTES.OUR_SERVICES:
                data.title = "Hyprdrive – our services"
                data.description = "Hyprdrive – our services"
                data.url = "https://hyprdrive.asia/"
                data.keywork = ""
                data.imageUrl = `${location.origin}/assets/images/bg-hero-image.jpg`
                break;
            case APP_ROUTES.ABOUT_US:
                data.title = "Hyprdrive – about us"
                data.description = "Hyprdrive – about us"
                data.url = "https://hyprdrive.asia/"
                data.keywork = ""
                data.imageUrl = `${location.origin}/assets/images/bg-hero-image.jpg`
                break;
            case APP_ROUTES.FEATURED_EV:
                data.title = "Hyprdrive – view all evs"
                data.description = "Hyprdrive – view all evs"
                data.url = "https://hyprdrive.asia/"
                data.keywork = ""
                data.imageUrl = `${location.origin}/assets/images/bg-hero-image.jpg`
                break;
            default:
                data.title = "Hyprdrive – Simply Electric, Easy Leasing"
                data.description = "Hyprdrive – Simply Electric, Easy Leasing"
                data.url = `"https://hyprdrive.asia/"`
                data.keywork = ""
                data.imageUrl = `${location.origin}/assets/images/bg-hero-image.jpg`
                break;
        }
        this.updateMeta(data)
    }

    updateMeta(data: any) {
        if (data.title) {
            this.titleService.setTitle(data.title)
        }
        if (data.description) {
            this.meta.updateTag({ name: 'description', content: data.description })
        }
        if (data.keywork) {
            this.meta.updateTag({ name: 'keywords', content: data.keywork })
        }
        if (data.title) {
            this.meta.updateTag({ property: 'og:title', content: data.title })
        }
        if (data.url) {
            this.meta.updateTag({ property: 'og:url', content: `${data.url}` })
        }
        if (data.description) {
            this.meta.updateTag({ property: 'og:description', content: data.description })
        }
        if (data.keywork) {
            this.meta.updateTag({ property: 'og:keywords', content: data.keywork })
        }
        if (data.imageUrl) {
            this.meta.updateTag({ property: 'og:image', content: data.imageUrl })
        }
    }
}