import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AboutUsComponent } from './pages/about-us/about-us.component';
import { ChargingSolutionsComponent } from './pages/charging-solutions/charging-solutions.component';
import { ContactUsComponent } from './pages/contact-us/contact-us.component';
import { FeaturedEvComponent } from './pages/featured-ev/featured-ev.component';
import { HomeComponent } from './pages/home/home.component';
import { OurServicesComponent } from './pages/our-services/our-services.component';
import { APP_ROUTES } from './shared/common/app-routes';
import { NotFoundComponent } from './shared/components/not-found/not-found.component';

const routes: Routes = [
  {
    path: '',
    redirectTo: '/home',
    pathMatch: 'full'
  },
  {
    path: APP_ROUTES.HOME,
    component: HomeComponent,
  },
  {
    path: APP_ROUTES.CONTACT_US,
    component: ContactUsComponent,
  },
  {
    path: APP_ROUTES.CHARGING_SOLUTIONS,
    component: ChargingSolutionsComponent,
  },
  {
    path: APP_ROUTES.OUR_SERVICES,
    component: OurServicesComponent,
  },
  {
    path: APP_ROUTES.ABOUT_US,
    component: AboutUsComponent,
  },
  {
    path: APP_ROUTES.FEATURED_EV,
    component: FeaturedEvComponent,
  },

  { path: APP_ROUTES.CAR, loadChildren: () => import(`./pages/cars/cars.module`).then(m => m.CarsModule) },

  { path: APP_ROUTES.NOT_FOUND, component: NotFoundComponent },
  { path: '**', component: NotFoundComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    initialNavigation: 'enabledBlocking'
  })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
