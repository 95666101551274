
export const APP_ROUTES = {
    HOME: 'home',
    FEATURED_EV: 'featured-ev',
    NOT_FOUND: 'not-found',
    CAR: 'cars',
    SUBSCRIPTION: 'subscription',
    CONTACT_US: 'contact-us',
    CHARGING_SOLUTIONS: 'charging-solutions',
    OUR_SERVICES: 'our-services',
    ABOUT_US: 'about-us',
}