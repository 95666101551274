import { Component, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { APP_ROUTES } from '../../shared/common/app-routes';
import { MENUS } from '../../shared/common/menus';
import { environment as config } from '../../../environments/environment'

@Component({
  selector: 'app-nav-menu',
  templateUrl: './nav-menu.component.html',
  styleUrls: ['./nav-menu.component.scss']
})
export class NavMenuComponent implements OnInit {
  MENUS = MENUS;
  loading = false;

  constructor(
    private router: Router,
  ) { }

  ngOnInit() {
    this.router.events.subscribe((evt) => {
      if (!(evt instanceof NavigationEnd)) {
        return;
      }
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      })
    });
  }

  enquiry() {
    this.router.navigate([APP_ROUTES.CONTACT_US]);
  }

  openSGSite() {
    this.loading = true;
    window.location.replace(config.sg_website_url);
  }
}
