<app-home-header></app-home-header>
<section id="home-hero-image" class="">
    <div class="jumbotron-fluid hero-image 	remove-space">
        <div class="row align-middle banner-img">
            <!-- <div class="banner-img"><img src="../../../assets/images/img-hero-evs.png" alt="" /></div> -->
            <div id="carouselExampleIndicators" class="carousel slide" data-ride="carousel">
                <ol class="carousel-indicators">
                    <li data-target="#carouselExampleIndicators" data-slide-to="0" class="active"></li>
                    <li data-target="#carouselExampleIndicators" data-slide-to="1"></li>
                </ol>
                <div class="carousel-inner">
                    <div class="carousel-item active"><img src="../../../assets/images/bg-hero-image.jpg" alt="" />
                    </div>
                    <div class="carousel-item"><img src="../../../assets/images/bg-hero-image2.jpg" alt="" /></div>
                </div>
                <a class="carousel-control-prev" href="#carouselExampleIndicators" role="button" data-slide="prev">
                    <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                    <span class="sr-only">Previous</span>
                </a>
                <a class="carousel-control-next" href="#carouselExampleIndicators" role="button" data-slide="next">
                    <span class="carousel-control-next-icon" aria-hidden="true"></span>
                    <span class="sr-only">Next</span>
                </a>
            </div>
        </div>

        <div class="banner-tagline">
            <div class="banner-logo"><img src="../../../assets/images/logo-hyprdrive-banner.png" alt="" /></div>
        </div>
    </div>
</section>
<!--section id="partner-logo">
    <div class="container">
        <div class="row">
            <div class="col-md-3 col-6 clickable" *ngFor="let carMake of carMakes"><img src="{{carMake?.imageUrl}}"
                    alt="" (click)="navigateCarFeatured(carMake.id)" />
            </div>
        </div>
    </div>
</section-->
<section id="leasing-solution">
    <div class="container p60-top-bottom remove-space">
        <div class="row">
            <div class="col-md-12 text-center">
                <h1 class="title">Leasing Solutions for Businesses</h1>
            </div>
            <div class="col-md-4">
                <img src="../../../assets/images/img-lease01.jpg" alt="" />
                <h2 class="mt-3"><strong>Fleet Electrification</strong></h2>
                <p>We build a total complete ecosystem of services that include EV leasing, smart fleet charging
                    infrastructure and sustainable power grid solutions.</p>
            </div>
            <div class="col-md-4">
                <img src="../../../assets/images/img-lease02.jpg" alt="" />
                <h2 class="mt-3"><strong>Subscribe to Own</strong></h2>
                <p>Step into the journey of transition by experiencing EVs through leasing and ultimately choosing one
                    to be your own.</p>
            </div>
            <div class="col-md-4">
                <img src="../../../assets/images/img-lease03.jpg" alt="" />
                <h2 class="mt-3"><strong>Subscription Plans</strong></h2>
                <p>Enjoy the flexibility to swap your EV periodically regardless of the vehicle category during your
                    subscription</p>
            </div>

        </div>
    </div>
</section>
<section id="car-carousell" class="bg-blue p60-top-bottom remove-space">
    <div class="container-fluid">
        <div class="row">
            <div class="col-md-12 text-center">
                <h1 class="title">Featured EVs</h1>
            </div>
        </div>
        <div class="row">
            <div class="col-md-12 content">
                <ul id="car-carousell-list">
                    <li *ngFor="let item of listCarCarousell" class="clickable" (click)="viewDetails(item.key)">
                        <img [src]="item.image" alt="" />
                        <div class="car-card" style="height: 40px;">
                            <strong>{{item.name}}</strong>
                            <!--span>FROM {{item.price | pricingFormat}} {{convertFrequency(item.frequency)}}</span-->
                        </div>
                        <a class="view-details" routerLink="/{{APP_ROUTES.CAR}}/{{item.key}}">View Details
                            <span><i class="fa fa-arrow-right"></i></span>
                        </a>
                    </li>
                </ul>
                <div class="pre-next" style="font-size: 18px;" *ngIf="listCarCarousell.length">
                    <a (click)="pre()">
                        <span><i class="fa fa-angle-left"></i></span>
                    </a>
                    <a (click)="next()">
                        <span><i class="fa fa-angle-right"></i></span>
                    </a>
                </div>
            </div>
        </div>
    </div>
</section>
<section id="highlight-banner" class="p60-top-bottom remove-space">
    <div class="container remove-space">
        <div class="row">
            <div class="col-12">
                <a routerLink="/featured-ev">
                    <img class="desktop-ninja" src="../../../assets/images/dummy-hightlight.jpg" alt="" />
                    <img class="mobile-ninja" src="../../../assets/images/dummy-hightlight.jpg" alt="" />
                </a>
            </div>
        </div>
    </div>

</section>
<section id="why-us">
    <div class="container remove-space">
        <div class="row bg-content">
            <div class="col-lg-5 col-md-12">
                <h1 class="title">Why lease from us?</h1>
                <div class="wlfu">
                    <h2>Bundled with charging solutions</h2>
                    <p>Be it tapping on public charging network or installing your own, we’ve got that covered in our
                        leasing programme.</p>
                </div>
                <div class="wlfu">
                    <h2>No huge down payments</h2>
                    <p>Enjoy more financial freedom with lesser initial cash investment when you lease from us.</p>
                </div>
                <div class="wlfu">
                    <h2>Sale and leaseback programme</h2>
                    <p>We can develop a customised solution for you to trade in your existing fleet to offset the cost
                        of your newly leased EV fleet.</p>
                </div>
            </div>
        </div>
    </div>
</section>
<section id="why-us2">
    <div class="container remove-space">
        <div class="row bg-content">
            <div class="col-lg-5 col-md-12 offset-lg-7">
                <div class="wlfu">
                    <h2>Leave the maintenance to us</h2>
                    <p>Our in-house aftersales team will fully handle the maintenance and servicing of your EVs.</p>
                </div>
                <div class="wlfu">
                    <h2>Safety and driver-centric system</h2>
                    <p>Our EVs are enhanced with smart technologies to provide clients and drivers a complete EV
                        experience.</p>
                </div>
                <div class="wlfu">
                    <h2>Brand your business on our EVs</h2>
                    <p>We provide vehicle wrapping service as part of our programme to enable optimum branding exposure
                        for you.</p>
                </div>

            </div>
        </div>
    </div>
</section>
<section id="usp" class="p60-top-bottom remove-space">
    <div class="container p40-bottom">
        <div class="row">
            <div class="col-12 title text-center">
                <h1>What’s included in our leasing programme?</h1>
            </div>
        </div>
        <div class="row">
            <div class="col-md-4 col-6">
                <img src="../../../assets/images/icon-usp1.jpg" alt="" />
                <h2>Insurance &<br />Road tax Covered</h2>
            </div>
            <div class="col-md-4 col-6">
                <img src="../../../assets/images/icon-usp2.jpg" alt="" />
                <h2>Maintenance &<br /> Servicing</h2>
            </div>
            <div class="col-md-4 col-6">
                <img src="../../../assets/images/icon-usp3.jpg" alt="" />
                <h2>Replacement<br />Vehicle</h2>
            </div>
            <div class="col-md-4 col-6">
                <img src="../../../assets/images/icon-usp4.jpg" alt="" />
                <h2>24/7 Customer<br />Service</h2>
            </div>
            <div class="col-md-4 col-6">
                <img src="../../../assets/images/icon-usp5.jpg" alt="" />
                <h2>Charging<br />Solutions</h2>
            </div>
            <div class="col-md-4 col-6">
                <img src="../../../assets/images/icon-usp6.jpg" alt="" />
                <h2>Smart technology<br />features</h2>
            </div>
        </div>
    </div>
</section>
<!--section id="our-schemes">
    <div class="container">

        <div class="row align-middle">
            <div class="col-lg-8 p60-top-bottom remove-space">
                <img src="../../../assets/images/img-drive-new.jpg" alt="" />
            </div>
            <div class="col-lg-4">
                <h1 class="title">OUR CORPORATE LEASING SCHEMES</h1>
                <div class="os">
                    <h2><strong>Lease To Own</strong></h2>
                    <p>Buy the leased EV after 7 years</p>
                </div>
                <div class="os">
                    <h2><strong>Sell & Lease Back</strong></h2>
                    <p>Sell to us your ICE vehicle and lease an EV from us</p>
                </div>
                <div class="os">
                    <h2><strong>Pay Per Use</strong></h2>
                    <p>Go on a fixed monthly rate and top up for additional mileage used</p>
                </div>
                <div class="btn btn-gradient"><a routerLink="/featured-ev">Browse our EVs <i
                            class="fa-solid fa fa-arrow-right"></i></a></div>
            </div>
        </div>

    </div>
</section-->
<section id="how-it-work">
    <div class="container remove-space">
        <div class="bg-content">
            <div class="row">
                <div class="col-lg-12">
                    <h1 class="title">How does it work?</h1>
                </div>
            </div>
            <div class="row">
                <div class="col-lg-4">
                    <div class="hdiw">
                        <span>1</span>
                        <h2>Browse through our EV product catalogue and add to cart select your desired EV model.</h2>
                    </div>
                    <div class="hdiw">
                        <span>2</span>
                        <h2>Select your lease plan: 12 months, 24 months or 36 months.</h2>
                    </div>
                    <div class="hdiw">
                        <span>3</span>
                        <h2>Select your mileage plan: Lite or Standard. </h2>
                    </div>
                </div>
                <div class="col-lg-4">
                    <div class="hdiw">
                        <span>4</span>
                        <h2>Proceed to checkout and reserve the EV.</h2>
                    </div>
                    <div class="hdiw">
                        <span>5</span>
                        <h2>Sit back, relax. Our sales consultant will follow up with you within 1 business day.</h2>
                    </div>
                </div>
            </div>

        </div>
    </div>
</section>
<section id="why-go-electric">
    <div class="container remove-space">
        <div class="row bg-content">
            <div class="col-lg-5 col-md-12 offset-lg-7">

                <h1 class="title">IT’S TIME TO ELECTRIFY YOUR FLEET.</h1>
                <ul>
                    <li>Enhance your environmental credentials with reduced carbon footprint</li>
                    <li>Enjoy cost savings from lower maintenance & electricity costs</li>
                    <li>Improved safety due to better stability with EV’s lower centre of gravity</li>
                    <li>Growing charging network reduces range anxiety</li>
                    <li>Reduced noise pollution while experiencing a relaxing and silent ride with EVs</li>
                </ul>

            </div>
        </div>
    </div>

</section>