import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpErrorResponse, HttpStatusCode } from '@angular/common/http';
import { catchError, Observable, retry, throwError } from 'rxjs';
import { APP_ROUTES } from '../common/app-routes';
import { Router } from '@angular/router';

@Injectable()
export class ErrorHandlingInterceptor implements HttpInterceptor {
  NETWORK_ERROR = 'No internet. Please check and try again.';
  SERVER_ERROR = 'Server error. Please try again later!';

  constructor(
    private router: Router,
  ) { }

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    return next.handle(request)
      .pipe(
        retry(0),
        catchError((httpErrorResponse: HttpErrorResponse) => {
          let message = '';
          if (httpErrorResponse.error instanceof ErrorEvent) {
            // handle client-side error
            message = this.NETWORK_ERROR;
            return throwError(() => { return { status: HttpStatusCode.NetworkAuthenticationRequired, message: this.NETWORK_ERROR }; });
          } else {
            // handle server-side error
            switch (httpErrorResponse.status) {
              case 0: // unknown error, unable connect to server
                return throwError(() => { return { status: 0, message: this.SERVER_ERROR }; });
              case HttpStatusCode.BadRequest:
                return throwError(() => { return { status: 0, message: httpErrorResponse.error && httpErrorResponse.error.message }; });
              case HttpStatusCode.Unauthorized:
                // [TODO] go to login
                // this.usersHelper.clearUserLoggedIn();
                // this.router.navigate([`/${APP_ROUTES.LOGIN}`]);
                return throwError(() => { return httpErrorResponse; });
              default:
                return throwError(() => { return { status: httpErrorResponse.status, message: this.SERVER_ERROR }; });
            }
          }
        })
      );
  }
}
