import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { APP_ROUTES } from '../../shared/common/app-routes';
import { MENUS } from '../../shared/common/menus';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  MENUS = MENUS;
  constructor(
    private router: Router,
  ) { }

  ngOnInit(): void {
  }

  enquiry() {
    this.router.navigate([APP_ROUTES.CONTACT_US]);
  }
}
