import { Component, Inject, OnInit, PLATFORM_ID } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { APP_ROUTES } from '../../shared/common/app-routes';
import { CONSTANTS } from '../../shared/common/constants';
import { ContactsService } from '../../shared/services/contacts/contacts.service';
import { UtilsService } from '../../shared/services/utils/utils.service';
import { environment as config } from '../../../environments/environment'
import { isPlatformBrowser } from '@angular/common';
import { ModelsService } from '../../shared/services/models/models.service';
declare var grecaptcha: any;

@Component({
  selector: 'app-contact-us',
  templateUrl: './contact-us.component.html',
  styleUrls: ['./contact-us.component.scss']
})
export class ContactUsComponent implements OnInit {
  isBrowser: boolean = false;

  submitted = false;
  CONSTANTS = CONSTANTS;
  EV_TYPE = {
    EV_CARS: 'ev_car',
    EV_VANS: 'ev_van',
  }
  loading = false;
  siteKey: string = '';

  contactForm: FormGroup = new FormGroup({
    firstName: new FormControl('', [Validators.required]),
    lastName: new FormControl('', [Validators.required]),
    contactNumber: new FormControl('', [Validators.required]),
    email: new FormControl('', [Validators.required]),
    companyName: new FormControl('', [Validators.required]),
    vehicleCategory: new FormControl('', [Validators.required]),
    makeModel: new FormControl(''),
    tenure: new FormControl(''),
    purpose: new FormControl(''),
    industryType: new FormControl(''),
  });

  cars = [] as any;
  makeModelToShow = [] as any;
  constructor(
    @Inject(PLATFORM_ID) platformId: Object,

    private router: Router,
    private utilsService: UtilsService,
    private contactsService: ContactsService,
    private modelsService: ModelsService,
  ) {
    this.isBrowser = isPlatformBrowser(platformId);
  }

  ngOnInit(): void {
    this.getDataFeaturedEV();

    this.siteKey = config.siteKeyCaptcha;
    const self = this;
    setTimeout(() => {
      if (self.isBrowser) {
        grecaptcha.ready(function () {
          grecaptcha.render('captcha_element_enquiry_form', {
            sitekey: self.siteKey,
            size: 'invisible'
          });
        });
      }
    }, 100);
  }

  getDataFeaturedEV() {
    let self = this;
    this.modelsService.getDataFeaturedEV().subscribe({
      next(result) { handleSuccess(result); },
      error(error) { handleError(error) },
    });

    function handleSuccess(result: any) {
      self.cars = result.data;
    }
    function handleError(error: any) {
      self.utilsService.notifyError(error.message);
    }
  }

  onChangeVehicleCategory() {
    const vehicleCategory = this.f['vehicleCategory'].value;
    if (vehicleCategory) {
      if (vehicleCategory === 'Passenger') {
        this.makeModelToShow = this.cars.filter((item: { kind: string; }) => {
          return item.kind === this.EV_TYPE.EV_CARS;
        });
      } else {
        this.makeModelToShow = this.cars.filter((item: { kind: string; }) => {
          return item.kind === this.EV_TYPE.EV_VANS;
        });
      }
    } else {
      this.makeModelToShow = [];
    }
  }

  get f(): { [key: string]: AbstractControl } {
    return this.contactForm.controls;
  }

  submit() {
    this.submitted = true;
    if (this.contactForm.invalid) {
      return;
    }

    let self = this;
    grecaptcha.ready(function () {
      grecaptcha.execute({ action: 'enquiry_form' }).then(async function (reCaptchaToken: string) {
        self.loading = true;
        self.contactsService.create(self.contactForm.value, reCaptchaToken).subscribe({
          next(result) { handleSuccess(result); },
          error(error) { handleError(error) },
        });

        function handleSuccess(result: any) {
          self.loading = false;
          self.router.navigate([`${APP_ROUTES.CAR}/${APP_ROUTES.SUBSCRIPTION}/done`]);
        }
        function handleError(error: any) {
          self.loading = false;
          self.utilsService.notifyError(error.message);
        }
      })
    })
  }
}
