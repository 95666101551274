import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ModelsService } from 'src/app/shared/services/models/models.service';
import { APP_ROUTES } from '../../shared/common/app-routes';
import { CarMakesService } from '../../shared/services/car-makes/car-makes.service';
import { UtilsService } from '../../shared/services/utils/utils.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
  carMakes = [] as any;
  listCarCarousell = [] as any;
  APP_ROUTES = APP_ROUTES;
  constructor(
    private utilsService: UtilsService,
    private carMakesService: CarMakesService,
    private modelsService: ModelsService,
    private router: Router
  ) {
  }

  ngOnInit(): void {
    // this.getAllCarMakes();
    this.getDataFeaturedEV();
  }

  getAllCarMakes() {
    let self = this;
    this.carMakesService.getAll().subscribe({
      next(result) { handleSuccess(result); },
      error(error) { handleError(error) },
    });

    function handleSuccess(result: any) {
      self.carMakes = result && result.data || [];
    }
    function handleError(error: any) {
      self.utilsService.notifyError(error.message)
    }
  }

  getDataFeaturedEV() {
    let self = this;
    this.modelsService.getDataFeaturedEV().subscribe({
      next(result) { handleSuccess(result); },
      error(error) { handleError(error) },
    });

    function handleSuccess(result: any) {
      self.listCarCarousell = result.data;
    }
    function handleError(error: any) {
      self.utilsService.notifyError(error.message);
    }
  }

  viewDetails(key: string) {
    this.router.navigate([`${APP_ROUTES.CAR}/${key}`]);
  }

  convertFrequency(frequency: string) {
    switch (frequency) {
      case 'W':
        return " / Week"
      case 'M':
        return '/ Month'
      case 'D':
        return '/ Day'
      default:
        return ''
    }
  }

  pre() {
    const element = document.getElementById('car-carousell-list');
    if (element) {
      element.scrollLeft -= 250;
    }
  }

  next() {
    const element = document.getElementById('car-carousell-list');
    if (element) {
      element.scrollLeft += 250;
    }
  }

  navigateCarFeatured(makeId: any) {
    const queryParams = {
      makeId
    }
    const path = `${APP_ROUTES.FEATURED_EV}`
    this.router.navigate([path], { queryParams });
  }

}
