import { Component, OnInit } from '@angular/core';
import { APP_ROUTES } from '../../shared/common/app-routes';
import { CONSTANTS } from '../../shared/common/constants';
import { MENUS } from '../../shared/common/menus';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
  MENUS = MENUS;
  APP_ROUTES = APP_ROUTES;
  COMPANY_INFO = CONSTANTS.COMPANY_INFO;

  constructor() { }

  ngOnInit(): void {
  }

}
