import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment as config } from '../../../environments/environment'

@Injectable({
  providedIn: 'root'
})
export class BaseService {
  apiURL = '';

  constructor(protected http: HttpClient) { }

  private createHttpOptions(params?: any, headersPairs?: any) {
    let myHeaders = {
      [config.authorization_key]: config.authorization_value,
      'Content-Type': 'application/json',
    } as any;
    if (headersPairs) {
      Object.keys(headersPairs).forEach(key => {
        myHeaders[key] = headersPairs[key];
        if (key === 'Content-Type' && !headersPairs[key]) {
          delete myHeaders[key];
        }
      });
    }

    let headers = new HttpHeaders(myHeaders);

    return { headers, params };
  }

  get(path: string, params?: any): Observable<any> {
    return this.http
      .get<any>(`${this.apiURL}/${path}`, this.createHttpOptions(params));
  }

  post(path: string, body: any, headersPairs?: any): Observable<any> {
    return this.http
      .post<any>(`${this.apiURL}/${path}`, body, this.createHttpOptions(null, headersPairs));
  }

  patch(path: string, body: any): Observable<any> {
    return this.http
      .patch<any>(`${this.apiURL}/${path}`, body, this.createHttpOptions());
  }

  delete(path: string): Observable<any> {
    return this.http
      .delete<any>(`${this.apiURL}/${path}`);
  }
}
