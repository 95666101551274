<div class="pc-nav collapse navbar-collapse" id="navbarSupportedContent">
    <ul class="navbar-nav mr-auto">
        <li class="nav-item" *ngFor="let item of MENUS"> <a class="nav-link"
                routerLink="/{{item.href}}">{{item.name}}</a>
        </li>
    </ul>
    <button class="btn btn-top-enquiry my-2 my-sm-0" (click)="enquiry()">Enquiry</button>

    <div class="dropdown">
        <div class="dropbtn" style="max-width: 30px;">
            <img src="../../../assets/images/my_lang.png" alt="Language">
        </div>
        <div class="dropdown-content">
            <a>Malaysia</a>
            <a (click)="openSGSite()">Singapore</a>
        </div>
    </div>
</div>

<div class="phone-nav container">
    <div class="phone">
        <div class="content">
            <nav role="navigation">
                <div id="menuToggle">
                    <input type="checkbox" />
                    <span></span>
                    <span></span>
                    <span></span>
                    <ul id="menu">
                        <li *ngFor="let item of MENUS">
                            <a class="nav-link" routerLink="/{{item.href}}">{{item.name}}</a>
                        </li>
                        <li>
                            <button class="btn btn-top-enquiry my-2 my-sm-0" (click)="enquiry()">Enquiry</button>
                        </li>
                        <li>
                            <div class="dropdown">
                                <div class="dropbtn">
                                    <img style="max-width: 30px;" src="../../../assets/images/my_lang.png"
                                        alt="Language">
                                    Malaysia
                                    <i class="fa fa-caret-down" aria-hidden="true"></i>
                                </div>
                                <div class="dropdown-content">
                                    <a>Malaysia</a>
                                    <a (click)="openSGSite()">Singapore</a>
                                </div>
                            </div>
                        </li>
                    </ul>
                </div>
            </nav>
        </div>
    </div>
</div>
<ngx-loading [show]="loading" [config]="{ backdropBorderRadius : '3px', fullScreenBackdrop: true }"></ngx-loading>