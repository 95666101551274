<app-header></app-header>
<section class="p60-top-bottom">
    <div class="container">
        <div class="row mt-4 mt-md-0">
            <div class="col-12">
                <h1 class="main-title">Charging Solutions</h1>
            </div>
        </div>
        <div class="row  p40-bottom">
            <div class="col-lg-8 public-charging">
                <img class="p40-bottom" src="../../../assets/images/img-charge-solution.jpg" alt="" />
            </div>
            <div class="col-lg-4 col-md-12">
                <h2><strong>Public Charging</strong></h2>
                <h2 class="p40-bottom">Complimentary chargEV Membership</h2>
                <p>Our leasing programme comes with complimentary chargEV membership for the whole period of lease. With
                    over 400 charging locations in Malaysia, enjoy the convenience of its wide network with
                    complimentary charging.</p>
                <a href="https://chargev.my/" target="_blank" class="btn btn-gradient">Visit ChargEV <i
                        class="fa-solid fa fa-arrow-right"></i></a>
            </div>
        </div>
        <div class="row install-charger ">
            <div class="col-lg-4 col-md-12 p40-bottom">
                <h2><strong>Install Chargers</strong></h2>
                <h2 class="p40-bottom">Turnkey Charging Solutions</h2>
                <p>Depending on your requirements, we can offer charger installation services for your fleet charging
                    needs.</p>
                <a href="https://chargev.my/" target="_blank" class="btn btn-gradient">Visit ChargEV <i
                        class="fa-solid fa fa-arrow-right"></i></a>
            </div>
            <div class="col-lg-4 col-md-12 charger p40-bottom">
                <img src="../../../assets/images/charger1.jpg" alt="" />
                <strong>chargEV AC Charger</strong>
            </div>
            <div class="col-lg-4 col-md-12 charger p40-bottom">
                <img src="../../../assets/images/charger2.jpg" alt="" />
                <strong>chargEV DC Charger</strong>
            </div>
        </div>
        <div class="row install-charger ">
            <div class="col-lg-4 col-md-12 p40-bottom">
                <h2><strong>Cross Border Charging</strong></h2>
                <h2 class="p40-bottom">(coming soon)</h2>
                <p>Forget about range anxiety and charging-related concerns as we will be expanding our EV charging network presence in Singapore! Experience a smooth, enhanced and seamless connectivity when you travel from Malaysia to Singapore – from the chargEV app to any of our chargers in Singapore plugged into your EV, easily and conveniently.</p>

            </div>
            <div class="col-lg-8 col-md-12 charger p40-bottom">
                <img src="../../../assets/images/ev-hub.jpg" alt="" />
            </div>

        </div>
    </div>
</section>
<section class="bg-blue p60-top-bottom text-center download-app">
    <div class="container">
        <div class="row align-middle">
            <div class="col-md-6"><img src="../../../assets/images/img-download-app.png" alt="" /></div>
            <div class="col-md-6">
                <h2><strong>Get the chargEV app</strong></h2>
                <p>We are expanding chargEV’s presence rapidly across Malaysia. Download the app to locate your nearest
                    chargEV. </p>
                <h2><strong>Scan & Download Now</strong></h2>
                <span><img src="../../../assets/images/qr-apple.jpg" alt="" /></span><span><img
                        src="../../../assets/images/qr-android.jpg" alt="" /></span>
                <a href="https://www.yinson.com/qr?code=ChargEVApple" target="_blank"><img
                        src="../../../assets/images/apple.png" alt="" /></a><a
                    href="https://www.yinson.com/qr?code=ChargEVGooglePlay" target="_blank"><img
                        src="../../../assets/images/android.png" alt="" /></a>
            </div>
        </div>
    </div>
</section>