<footer>
    <div class="container">
        <div class="row">
            <div class="col-md-3">
                <img class="footer-logo" src="../../../assets/images/hyprdrivelogo.png" alt="" />
                <strong>Malaysia</strong>
                <p>Level 16, Menara South Point, <br />Mid Valley City, <br />Medan Syed Putra Selatan, <br />59200
                    Kuala Lumpur</p>
                <p><b>Tel :</b> +60 111 6633 098 <br /><b>Email : </b> <a target="_blank"
                        href="mailto:hello@hyprdrive.asia">hello@hyprdrive.asia</a></p>

                <strong>Singapore</strong>
                <p>3 Church Street #14-01 Samsung Hub 049483</p>
                <p><b>Tel :</b> +65 9016 5656 <br /><b>Email : </b> <a target="_blank"
                        href="mailto:hello@hyprdrive.asia">hello@hyprdrive.asia</a></p>
            </div>
            <div class="col-md-3">
                <ul>
                    <li><a routerLink="/">Home</a></li>
                    <li *ngFor="let item of MENUS"><a routerLink="{{item.href}}">{{item.name}}</a></li>
                    <li><a routerLink="{{APP_ROUTES.CONTACT_US}}">Enquiry Form</a></li>
                </ul>
            </div>
            <div class="col-md-4">
                <div class="social-media">
                    <strong>Find us on</strong>
                    <a href="{{COMPANY_INFO.FACEBOOK_LINK}}" target="_blank">
                        <img src="../../../assets/images/icon-linkedin.png" alt="" />
                        <span>Facebook</span>
                    </a>
                </div>
                <div class="copyright">
                    <p>© 2022 Yinson Green Technologies Sdn. Bhd. <br />202101010805 (1411104-H) <br />All rights
                        reserved.
                        <br /><br />Powered by Yinson GreenTech in collaboration with GoCar Mobility Sdn. Bhd.
                    </p>
                </div>
            </div>
        </div>
    </div>
</footer>