import { NgModule } from '@angular/core';
import { CommonModule, DecimalPipe } from '@angular/common';
import { UtilsService } from './services/utils/utils.service';
import { NotFoundComponent } from './components/not-found/not-found.component';
import { CarsService } from './services/cars/cars.service';
import { PricingFormatPipe } from './pipes/pricing-format.pipe';
import { BookingsService } from './services/bookings/bookings.service';
import { CarMakesService } from './services/car-makes/car-makes.service';
import { HeaderComponent } from '../templates/header/header.component';
import { ContactsService } from './services/contacts/contacts.service';
import { ModelsService } from './services/models/models.service';
import { NavMenuComponent } from '../templates/nav-menu/nav-menu.component';
import { RouterModule } from '@angular/router';
import { NgxLoadingModule } from 'ngx-loading';

@NgModule({
  declarations: [
    NotFoundComponent,
    PricingFormatPipe,
    HeaderComponent,
    NavMenuComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    NgxLoadingModule
  ],
  exports: [
    PricingFormatPipe,
    HeaderComponent,
    NavMenuComponent
  ],
  providers: [
    DecimalPipe,
    UtilsService,
    CarsService,
    BookingsService,
    CarMakesService,
    ContactsService,
    ModelsService
  ]
})
export class SharedModule { }
