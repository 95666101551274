<app-header></app-header>
<section id="featured-ev">
    <div class="container p60-top-bottom">
        <div class="row">
            <div class="col-md-6 main-title">
                <h1>View all {{title}}</h1>
            </div>
            <div class="col-md-6 main-title filter-block">
                <div class="filter-button">
                    <a class="btn-filter" [ngClass]="{ 'btn-filter-selected' : EVTypeSelected === EV_TYPE.ALL}"
                        (click)="filterByEVType(EV_TYPE.ALL)">
                        All EVs </a>
                    <a class="btn-filter" style="margin-left: 10px;"
                        [ngClass]="{ 'btn-filter-selected' : EVTypeSelected === EV_TYPE.EV_CARS}"
                        (click)="filterByEVType(EV_TYPE.EV_CARS)">
                        EV Cars</a>
                    <a class="btn-filter" style="margin-left: 10px;"
                        [ngClass]="{ 'btn-filter-selected' : EVTypeSelected === EV_TYPE.EV_VANS}"
                        (click)="filterByEVType(EV_TYPE.EV_VANS)">
                        EV Vans</a>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-md-12">
                <ul>
                    <li *ngFor="let item of carsToShow" class="clickable" (click)="viewDetails(item.key)">
                        <img [src]="item.image" alt="" />
                        <div class="car-card">
                            <strong>{{item.name}}</strong>
                            <span>FROM {{item.price | pricingFormat}} {{convertFrequency(item.frequency)}}</span>
                        </div>
                        <a class="view-details" routerLink="/{{APP_ROUTES.CAR}}/{{item.key}}">View Details
                            <span><i class="fa fa-arrow-right"></i></span>
                        </a>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</section>
<ngx-loading [show]="loading" [config]="{ backdropBorderRadius : '3px', fullScreenBackdrop: true }"></ngx-loading>