<app-header></app-header>
<section>
    <div class="container p60-top-bottom">
        <div class="row p40-bottom">
            <div class="col-lg-4">
                <h1 class="main-title">about Us</h1>
                <p>Hyprdrive aims to deploy affordable and accessible low carbon products and services enhanced with
                    innovative smart solutions that will pave the way for faster adoption of electric vehicles (EVs) in
                    both Singapore and Malaysia.
                </p>
                <p>
                    As an electrification partner and enabler, we provide E-Mobility Ecosystem services to companies
                    across both sides of the causeway that want to transition from internal combustion engines (ICE) to
                    electric vehicles (EV).
                </p>  
                <p>
                    Whether it is motorcycles, cars, transport or delivery vehicles, our
                    purpose-built, integrated e-mobility solutions enable our clients to achieve their sustainability
                    goals and reduce their carbon footprint.</p>
            </div>
            <div class="col-lg-8">
                <img src="../../../assets/images/img-about-us1.jpg" alt="" />
            </div>
        </div>

        <div class="row">
            <div class="col-12">
            <img class="p40-bottom" src="../../../assets/images/img-about2.jpg" alt="" />
            </div>
            <div class="col-12 order-last">
                <p>Yinson GreenTech (“YGT”) is the green technologies business unit of Yinson Holdings Berhad (“Yinson”,
                    or “the Group”), a global energy infrastructure and technology company headquartered in Malaysia.
                    The Group has businesses in offshore production, renewables, green technologies and offshore marine,
                    and a presence in 18 countries. </p>
        
                <p>YGT was established in 2020 as a green technologies solution provider delivering a clean, integrated
                    and technology-enhanced ecosystem across the marine, mobility and energy segments.  </p>
        
                <p>The business unit invests in novel green businesses, R&D and strategic partnerships to develop
                    integrated smart green assets and infrastructure. Ultimately, YGT aims to create a proprietary
                    digital marketplace that provides affordable and accessible tech-based low carbon products and
                    services to help businesses and communities achieve their own net zero ambitions.  </p>
        
                <p>YGT’s strategic investments currently include an advanced hydrofoil system for electric vessels,
                    e-bike and swappable batteries, autonomous and robotic technology, autonomous systems for electric
                    vehicles, marine energy storage solutions and electric vehicle charging solutions.   </p>
                <!--div><a class="btn btn-gradient" href="https://yinson.com/ygt" target="_blank">VISIT YINSON GREETECH <i class="fa-solid fa fa-arrow-right"></i></a></div-->
            </div>
        </div>
       
    </div>
</section>