
export const CONSTANTS = {
    LOADING_ANIMATION_COLOR: '#40bd9c',
    COMMON_STRING_STATUS: {
        YES: 'Y',
        NO: 'N'
    },
    NOTIFIER_OPTIONS: {
        position: {
            horizontal: {
                position: 'right',
            },
            vertical: {
                position: 'top',
            },
        },
        theme: 'material',
        behaviour: {
            autoHide: 4000,
            onClick: 'hide',
            showDismissButton: false,
        },
        animations: {
            enabled: true,
            show: {
                preset: 'slide',
                speed: 300,
                easing: 'ease',
            },
            hide: {
                preset: 'fade',
                speed: 300,
                easing: 'ease',
                offset: 50,
            },
            shift: {
                speed: 300,
                easing: 'ease',
            },
            overlap: 150,
        },
    },
    CONTACT_US_VEHICLE_CATEGORIES: ['Passenger', 'Commercial'],
    CONTACT_US_BUDGETS: ['<RM1,000', 'RM1001 – RM2,000', 'RM2,001 – RM3,000', 'RM3,001 – RM4,000', '>RM4,001'],
    CONTACT_US_TENURES: ['12 months', '24 months', '36 months', '48 months', '60 months'],
    CONTACT_US_PURPOSES: ['Individual', 'Business'],
    CONTACT_US_INDUSTRIES: ['Agriculture & Forestry/Wildlife', 'Business & Information', 'Construction/Utilities/Contracting', 'Education', 'Finance & Insurance',
        'Food & Hospitality', 'Gaming', 'Health Services', 'Motor Vehicle', 'Natural Resources/Environmental', 'Personal Services', 'Real Estate & Housing', 'Safety/Security & Legal', 'Transportation', 'Other'],

    COMPANY_INFO: {
        FACEBOOK_LINK: 'https://www.facebook.com/profile.php?id=100083121287525',
    }
}